import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Logo, LogoWrapper,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton,
    StepWrapper,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step7({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        handleFormSubmit(data);
        handleNextClick();
    }


    return <StepWrapper>
        <Title>סוג המשרה </Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>7. סוג משרה?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.availability === "fullTime"}
                        {...register("availability")}
                        value="fullTime"
                        name="availability"
                        id="radio1"
                        type="radio"/>
                    <OptionItemLabel width={200} htmlFor="radio1">משרה מלאה</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.availability === "partTime"}
                        {...register("availability")}
                        value="partTime"
                        name="availability" id="radio2"
                        type="radio"/>
                    <OptionItemLabel width={200} htmlFor="radio2">משרה חלקית</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.availability === "flex"}
                        {...register("availability")}
                        value="flex"
                        name="availability" id="radio3"
                        type="radio"/>
                    <OptionItemLabel width={200} htmlFor="radio3">גמיש </OptionItemLabel>
                </OptionItem>
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
