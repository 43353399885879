import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper,
    Heading,
    Label,
    Logo,
    LogoWrapper, QuestionLabel, ReverseButton,
    StepWrapper,
    TextInput,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function StepInit({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        handleNextClick();
        handleFormSubmit(data);
    }


    return <StepWrapper>
        <Title>אפשר להתחיל..</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>אנא מלאו את השאלון בצורה המדויקת ביותר כדי שנכיר אתכם הכי טוב.</QuestionLabel>
                <Label>שם מלא</Label>
                <TextInput required defaultValue={formData.name} placeholder={"השם שלך"} {...register("name")} />
                <Label>טלפון</Label>
                <TextInput required defaultValue={formData.phone} placeholder={"הטלפון שלך"} {...register("phone")} />
                <Label>מייל</Label>
                <TextInput required type="email" defaultValue={formData.email}
                           placeholder={"המייל שלך"} {...register("email")} />
                <Buttons><Button type="submit">המשך</Button><ReverseButton
                    onClick={handlePrevClick}>קודם</ReverseButton></Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
