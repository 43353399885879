import React from "react";
import {
    Button,
    Buttons,
    FormWrapper, Heading, Logo, LogoWrapper,
    ReverseButton,
    StepWrapper,
    SubTitle, ThanksImg,
    ThanksSubTitle,
    ThanksTitle,
    Title
} from "./style";

export function ThankYou({ handlePrevClick}) {
    return <StepWrapper>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <ThanksTitle>תודה שהקדשתם זמן
                למילוי השאלון :)</ThanksTitle>
            <ThanksSubTitle>הנציגים שלנו יעברו על התשובות שלך ויצרו איתך קשר בהקדם. בינתיים, שיהיה המון
                הצלחה.</ThanksSubTitle>
            <ThanksImg src={"/thank-you-image.png"} alt="thank you"/>
            <Buttons>
                
                
                <ReverseButton onClick={handlePrevClick}>חזור לשאלון</ReverseButton>
            </Buttons>
        </FormWrapper>
    </StepWrapper>
}
