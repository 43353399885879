import React,{useEffect,useState} from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Logo, LogoWrapper,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton,
    StepWrapper,
    Spacer,
    OptionExplanation,
    Title,
    Bold
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step1({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit,watch, formState: {errors}} = useForm();
    const [ShowSjobPath1, setShowSjobPath1] = useState(false);
    const [ShowSjobPath2, setShowSjobPath2] = useState(false);
    const [ShowSjobPath3, setShowSjobPath3] = useState(false);

    const onSubmit = data => {
        handleFormSubmit(data);
        handleNextClick();
    }
    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            
            if(value.job){
            if (value.job=== "pensioni"||                        
            value.job=== "elemntari_pension"||
            value.job=== "invesment_pension") {
                
                setShowSjobPath1(true)
                setShowSjobPath2(false)
                setShowSjobPath3(false)
           
            }

            if (value.job=== "hitum") {
                setShowSjobPath1(false)
                setShowSjobPath2(true)
                setShowSjobPath3(false)
    
            } 

            if (value.job=== "referant") {
                setShowSjobPath1(false)
                setShowSjobPath2(false)
                setShowSjobPath3(true)
     
            } 
            
            if (value.job === "mortgage" ||value.job === "realestate" 
            ||value.job === "invesment"   ||value.job === "elemntari") {
                setShowSjobPath1(false)
                setShowSjobPath2(false)
                setShowSjobPath3(false)
              
            }
        }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return <StepWrapper>
        <Title>אפשר להתחיל..</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>1. ענף שאתה מכוון אליו
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "pensioni"}
                        {...register("job")}
                        value="pensioni"
                        name="job"
                        id="radio1"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio1">פנסיוני</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "invesment"}
                        {...register("job")}                        
                        value="invesment"
                        name="job" 
                        id="radio2"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio2">השקעות</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "elemntari"}
                        {...register("job")}
                        value="elemntari"
                        name="job"
                        id="radio3"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio3">אלמנטרי</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "elemntari_pension"}
                        {...register("job")}                        
                        value="elemntari_pension"
                        name="job"
                        id="radio4"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio4">פנסיוני+אלמנטרי</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "invesment_pension"}
                        {...register("job")}
                        value="invesment_pension"
                        name="job"
                        id="radio5"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio5">פנסיוני+השקעות</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "hitum"}
                        {...register("job")}
                        value="hitum"
                        name="job"
                        id="radio6"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio6">חיתום</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "referant"}
                        {...register("job")}
                        value="referant"
                        name="job"
                        id="radio7"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio7">רפרנט/ית</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "mortgage"}
                        {...register("job")}                        
                        value="mortgage"
                        name="job"
                        id="radio8"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio8">ייעוץ משכנתאות</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "realestate"}
                        {...register("job")}                        
                        value="realestate"
                        name="job"
                        id="radio9"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio9">השקעות נדל"ן</OptionItemLabel>
                </OptionItem>
                <>
                <Spacer height={30}/>
                </>
                        {
                    ShowSjobPath1 && (
                        <>
                            <Spacer height={20}/>
                       

                <QuestionLabel>4. האם את/ה מעוניין/ת להיות שכיר/ה או עצמאי/ת ?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.employmentType === "Employee"}
                        {...register("employmentType")}
                        value="Employee"
                        name="employmentType"
                        id="radio10"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio10">שכיר</OptionItemLabel>
                </OptionItem>
                <OptionExplanation>שכיר מתעסק בעיקר בשיווק פנסיוני בחברות ביטוח או בסוכנויות על מודל של שכיר כלומר שכר
                    בסיס + בונוסים על מכירות שביצעת.
                </OptionExplanation>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.employmentType === "Independent"}
                        {...register("employmentType")}
                        value="Independent"
                        name="employmentType"
                         id="radio11"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio11">עצמאי</OptionItemLabel>
                </OptionItem>
                <OptionExplanation>כעצמאי המשמעות היא שאת/ה בונה לעצמך תיק לקוחות וההכנסה שלך גדלה ככל שהתיק גדל. ישנם
                    עצמאים שעובדים תחת בית סוכן שאת/ה מקבל/ת מהם מעטפת לוגיסטית, הדרכות ועוד.
                </OptionExplanation>
                <>
                <Spacer height={30}/>
                </>


                        </>
                )}


{
                    ShowSjobPath2 && (
                        <>
                            <Spacer height={20}/>
                       

                <QuestionLabel>בתחום?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "hitumPrivate"}
                        {...register("job")}
                        value="hitumPrivate"
                        name="job"
                        id="radio12"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio12">חיתום אלמנטרי פרט</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "hitumBusiness"}
                        {...register("job")}
                        value="hitumBusiness"
                        name="job" id="radio13"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio13">חיתום אלמנטרי עסקי</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "hitumMedical"}
                        {...register("job")}
                        value="hitumMedical"
                        name="job" id="radio14"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio14">חיתום רפואי</OptionItemLabel>
                </OptionItem>
                <>
                <Spacer height={30}/>
                </>
                        </>
                )}
                {
                    ShowSjobPath3 && (
                        <>
                            <Spacer height={20}/>
                       

                <OptionExplanation><Bold>בתחום?</Bold>
                </OptionExplanation>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "ref_pen"}
                        {...register("job")}
                        value="ref_pen"
                        name="job"
                        id="radio15"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio15">רפרנט/ית פנסיוני</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.job === "ref_elem"}
                        {...register("job")}
                        value="ref_elem"
                        name="job"
                         id="radio16"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio16">רפרנט/ית אלמנטרי</OptionItemLabel>
                </OptionItem>
                <>
                <Spacer height={30}/>
                </>
                        </>
                )}
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
