import styled from "styled-components";

export const Wrapper = styled.section`
  background: linear-gradient(180deg, #ADE8F4 0%, #48CAE4 100%);
  min-height: 100vh;
  padding-top: 50px;
  display: flex;
  max-width: 100vw;
  width: 100vw;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 1024px) {
    padding-top: 82px;
  }

`

export const Title = styled.h1`
  padding: 0 30px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (min-width: 1024px) {
    padding: 0 100px;
    font-size: 52px;
    line-height: 72px;
    width: 740px;
  }
`;

export const SubTitle = styled.h2`padding: 0 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 60px;
  @media (min-width: 1024px) {
    padding: 0 100px;
    font-size: 28px;
  }
`

export const LogoWrapper = styled.div`display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    position: fixed;
    left: 100px;
    top: 63px;
  }

  &.hideMobile {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }
  }
`;


export const Logo = styled.img` width: 158px;
  @media (min-width: 1024px) {
    width: 104px;
  }
`;

export const Heading = styled.img`width: 200px;
  @media (min-width: 1024px) {
    width: 130px;
  }`;

export const Shape = styled.img`
  width: 248px;
  display: block;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 575px;
    margin: 0 80px 0 auto;
  }
`;

export const Button = styled.button`/* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 0;
  background: #03045E;
  border-radius: 24px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;

  @media (min-width: 1024px) {
    cursor: pointer;
    padding: 15px 39px;
    font-size: 32px;
  }
`;

export const WelcomeButton = styled(Button)`
  position: fixed;
  right: 30px;
  bottom: 77px;
`;

export const ReverseButton = styled(Button)` background: none;
  border: 1px solid #03045E;
  color: #03045E`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;


`

export const FormWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  @media (min-width: 1024px) {
    padding: 0 100px;
    position: relative;
  }
`;

export const Form = styled.form`flex: 1;
  display: flex;
  flex-direction: column;

`;

export const PercentageWrapper = styled.div`height: 4px;
  background: #D0F7FF;
  display: flex;
  align-items: flex-end;
  margin-bottom: 45px;

  @media (min-width: 1024px) {
    width: 740px;
    position: absolute;
    top: 66px;
  }
`

export const PercentageStatus = styled.div`background: #023E8A;
  height: 4px;`

export const Label = styled.div`font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  @media (min-width: 1024px) {
    font-size: 28px;
    line-height: 42px;
  }
`

export const QuestionLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;

  @media (min-width: 1024px) {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: ${({subQuestion}) => subQuestion ? "20px" : "100px"} ;
    margin-top: ${({subQuestion}) => subQuestion ? "20px" : "0"} ;
  }
`

export const TextInput = styled.input`background: none;
  color: #03045E;
  border: none;
  border-bottom: 1px solid #0077B6;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 20px;

  &::placeholder {
    color: #0077B6;
  }

  @media (min-width: 1024px) {
    width: 740px;
    font-size: 25px;
    line-height: 36px;
  }
`
export const Bold = styled.label`font-weight: 800;`
export const Buttons = styled.div`
  margin-top: auto;
  justify-content: space-between;
  display: flex;
  align-items: self-start;
  flex-direction: row-reverse;
  padding-bottom: 20px;

  ${Button} {
    @media (min-width: 1024px) {
      width: 280px;
      border-radius: 42px;
    }
  }
`

export const OptionItem = styled.div`position: relative;`;

export const OptionItemLabel = styled.label`

          height: 36px;
          font-weight: 600;
          font-size: 18px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          border: 1px solid #03045E;
          border-radius: 50px;
          width: ${({width = 150}) => width}px;

          @media (min-width: 1024px) {
            cursor: pointer;
            font-size: 32px;
            height: 68px;
            width: ${({width = 280}) => width + 200}px;
          }
    `
;

export const OptionItemInput = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked ~ ${OptionItemLabel} {
    color: white;
    background: #03045E;
  }
`;

export const OptionExplanation = styled.p`font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 10px;
  }
`

export const Spacer = styled.div`
  height: ${({height}) => height}px`

export const ThanksTitle = styled.h1`font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  max-width: 205px;
  margin: 0 auto 32px;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 52px;
    line-height: 72px;
    max-width: none;
    padding-top: 200px;
  }
`

export const ThanksSubTitle = styled.h2`font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  text-align: center;
  max-width: 315px;
  margin: 0 auto 47px;

  @media (min-width: 1024px) {
    max-width: 500px;
    font-size: 28px;
    line-height: 42px;
  }
`
export const ThanksImg = styled.img`max-width: 314px;
  display: block;
  margin: 0 auto;

  @media (min-width: 1024px) {
    display: none;
  }
`
