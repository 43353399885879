import React from "react";
import {PercentageStatus, PercentageWrapper} from "./style";

export function StepPercentage({percentageInfo}) {
    return (
        <PercentageWrapper>
            <PercentageStatus style={{width: `${percentageInfo}%`}}/>
        </PercentageWrapper>
    )
}
