import React, {useEffect} from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Label, Logo, LogoWrapper, OptionExplanation,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton, Spacer,
    StepWrapper, TextInput,
    Title,
    Bold
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step5({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        handleFormSubmit(data);
        handleNextClick();
    }

    // useEffect(() => {
    //     if (formData?.internshipStatus !== "notStarted") {
    //         handleNextClick(6)
    //     }
    // }, [])


    return <StepWrapper>
        <Title>אופי המשרה</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>5. מה אופי המשרה שאתה מחפש ? </QuestionLabel>
                <TextInput defaultValue={formData.jobNature} placeholder={"רשום מה אתה מחפש במשרה"} {...register("jobNature")} />
                <OptionExplanation>אפשרויות בחירה לתחום  <Bold style={{fontWeight: "bold"}}> הפנסיוני</Bold> : תפקיד מכירות, שירות, תפעול 
                </OptionExplanation>
                <OptionExplanation>אפשרויות בחירה לתחום <Bold>השקעות</Bold>: שיווק, מכירות וקשרי לקוחות
                </OptionExplanation>
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
