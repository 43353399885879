import React, {useEffect, useMemo, useState} from "react";
import {Wrapper} from "./style";
import {WelcomePage} from "./welcome";
import {StepInit} from "./step-init";
import {Step1} from "./step1";
import {Step2} from "./step2";
import {Step3} from "./step3";
import {Step4} from "./step4";
import {Step5} from "./step5";
import {Step6} from "./step6";
import {Step7} from "./step7";
import {Step8} from "./step8";
import {Step9} from "./step9";
// import {Step10} from "./step10";
import {ThankYou} from "./thank-you";
import {jsonData} from "./requestConfig"
import axios from "axios";


export function Wizard() {
    const [step, setStep] = useState(-1);
    const [stepPercentage, setStepPercentage] = useState(0);
    const [formData, setFormData] = useState({})

    const [isConditionOneMet, setIsConditionOneMet] = useState(false);
    const [isConditionTwoMet, setIsConditionTwoMet] = useState(false);

    const handleNextPageClick = (page) => {
        if (isNaN(page)) {
            setStep(step + 1)
        } else {
            setStep(page)
        }
    }

    const handlePrevPageClick = () => {
        if (step === 0) return false;
        setStep(step - 1)
    }

    const handleSubmit = (data) => {
        setFormData({
            ...formData,
            ...data
        });
    };

    const handleConditionOne = (formData) => {
        
        
        console.log(formData)
        const body={
            "objecttype": 1000,
                "page_size": 1,
                "page_number": 1,
                "fields": "*",
                "query": "(pcfsystemfield1 = "+formData.phone+")",
                "sort_by": "pcfsystemfield1",
                "sort_type": "desc"
        }
        const data = JSON.stringify(body)
        axios.post(`https://api.powerlink.co.il/api/query?tokenid=${process.env.REACT_APP_TOKEN}`, data,{
            Headers: {"Content-Type": "application/json",
                     "Access-Control-Allow-Origin":"*"}
        }).then(res => {                                    
                if(res.data.data.Data.length>0)
                {  
                    console.log(res.data.data.Data)
                    console.log("Res")
                    console.log(res.data.data.Data[0].pcfsystemfield11)
                    console.log("jsonData")
                    console.log(jsonData.job[formData.job])
                    // if(jsonData.job[formData.job]==res.data.data.Data[0].pcfsystemfield91) 
                    if(jsonData.job[formData.job]==res.data.data.Data[0].pcfsystemfield11) 
                    {
                     
                        return res.data
                    }    
                    
                    submitForm_BACKUP();
                }else{  
                    submitForm_BACKUP()
                }
                
            
        }).catch(error=>{console.log(error)
            submitForm_BACKUP()
            // alert("היה בעיה עם שליחת הטופס ,בבקשה נסה שוב מאוחר יותר")
        })
    }

    // const handleConditionTwo = useMemo(() => {

    //     if (isConditionOneMet) {
    //         const data = {}
    //         axios.post("relatedURL", data).then(res => {
    //             if (res.ok) {
    //                  setIsConditionTwoMet(true);
    //             }
    //         })
    //     }
    // }, [isConditionOneMet]);

    // const submitForm = useMemo(() => {
    //     if (isConditionTwoMet) {
    //         const data = {}
    //         axios.post("relatedURL", data).then(res => {
    //             if (res.ok) {
    //                 setStep(10)
    //             }
    //         }).catch(err => {
    //             console.log(err)
    //         })
    //     }
    // }, [isConditionTwoMet]);

    const submitForm_BACKUP = async () => {
        try {

            let request = {}
            Object.keys(formData).forEach(k => {

                if (k === "region") {
                    request.pcfsystemfield5 = jsonData[k][formData[k]] || ""
                }
                // if (k === "industry") {
                //     request.pcfsystemfield11 = jsonData[k][formData[k]] || ""
                // }
                if (k === "employmentType") {
                    request.pcfsystemfield21 = jsonData[k][formData[k]] || ""
                }
                if (k === "stage") {
                    request.pcfsystemfield99 = jsonData[k][formData[k]] || ""
                }
                if (k === "examStage") {
                    const examsLeft = jsonData["examsLeft"][formData["examsLeft"]] || ""
                    const examsLeftToPass = jsonData["examsLeftToPass"][formData["examsLeftToPass"]] || ""
                    const examStage = jsonData[k][formData[k]] || ""
                    request.pcfsystemfield150 = examStage + " " + examsLeft + " " + examsLeftToPass
                }

                if (k === "internshipStatus") {
                    request.pcfsystemfield9 = jsonData[k][formData[k]] || ""
                }
                if (k === "jobNature") {
                    request.pcfsystemfield131 = formData[k] || ""
                }
                if (k === "availability") {
                    request.pcfsystemfield13 = jsonData[k][formData[k]] || ""
                }
                if (k === "salaryExpectation") {
                    request.pcfsystemfield93 = formData[k] || ""
                }
                if (k === "allowBackOfficeJobs") {
                    request.pcfsystemfield148 = formData[k] || ""
                }
                if (k === "job") {
                    request.pcfsystemfield11 = jsonData[k][formData[k]] || ""
                }
                if (k === "isCurrentlyInField") {
                    request.pcfsystemfield19 = jsonData[k][formData[k]] || ""
                }
                if (k === "name") {
                    request.name = formData[k] || ""
                }
                if (k === "email") {
                    request.pcfsystemfield3 = formData[k] || ""
                }
                if (k === "phone") {
                    request.pcfsystemfield1 = formData[k].replace(/\D/g, '') || ""
                }

            });

            request.pcfsystemfield26 = 1
            
            const data = JSON.stringify(request)
            const response = await axios.post(`https://api.powerlink.co.il/api/record/1000?tokenid=${process.env.REACT_APP_TOKEN}`, data, {
                Headers: {"Content-Type": "application/json"}
            })
        } catch (err) {
            console.log(err.message)
        }
    }

    // useEffect(() => {
    //     handleConditionTwo();
    // }, [isConditionOneMet])

    // useEffect(() => {
    //     submitForm();
    // }, [isConditionTwoMet])


    useEffect(() => {
        setStepPercentage((step * 100) / 10);
    }, [step])

    // useEffect(() => {
    //     console.info("Form submitted")
    //     if (step === 10) {
    //         submitForm().then(() => {
    //             console.info("Form submitted")
    //         });
    //     }
    // }, [formData])

    return (
        <Wrapper>
            {step === -1 && <WelcomePage handleNextClick={handleNextPageClick}/>}
            {step === 0 && <StepInit
                formData={formData}
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={10}
                handleFormSubmit={handleSubmit}                
            />
            }
            {step === 1 && <Step1
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }

            {step === 2 && <Step2
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }

            {step === 3 && <Step3
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }

            {step === 4 && <Step4
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }

            {step === 5 && <Step5
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }
            {step === 6 && <Step6
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }
            {step === 7 && <Step7
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }
            {step === 8 && <Step8
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
            />
            }
            {step === 9 && <Step9
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
                submitForm={handleConditionOne}
            />
            }

            {/* {step === 10 && <Step10
                handleNextClick={handleNextPageClick}
                handlePrevClick={handlePrevPageClick}
                stepPercentage={stepPercentage}
                handleFormSubmit={handleSubmit}
                formData={formData}
                submitForm={submitForm}
            />
            } */}

            {step === 10 && <ThankYou handlePrevClick={handlePrevPageClick}/>}
        </Wrapper>
    )
}
