import React from "react";
import {Heading, Logo, LogoWrapper, Shape, SubTitle, Title, WelcomeButton} from "./style";

export function WelcomePage({handleNextClick}) {
    return <>
        <Title>שלום,</Title>
        <SubTitle>אנא מלאו את השאלון בצורה המדויקת ביותר כדי שנכיר אתכם הכי טוב.</SubTitle>
        <LogoWrapper>
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <Shape src={"/shape@2x.png"}/>
        <WelcomeButton onClick={handleNextClick}>התחלת השאלון</WelcomeButton>
    </>
}
