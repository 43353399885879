import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Logo, LogoWrapper,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton,
    StepWrapper,
    TextInput,
    Spacer,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step2({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        handleFormSubmit(data);
        handleNextClick();
    }


    return <StepWrapper>
        <Title>אפשר להתחיל..</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>2. איפה אתה גר
                </QuestionLabel>
                <Spacer height={20}/>
                <TextInput defaultValue={formData.address} placeholder={"מקום מגורים"} {...register("address")} />
                <Spacer height={20}/>
               <QuestionLabel>יש לנו משרות בכל הארץ, האם יש אזור מועדף?</QuestionLabel>
                <OptionItem>
                    <OptionItemInput                    
                        defaultChecked={formData.region === "north"}
                        {...register("region")}
                        value="north"
                        name="region"
                        id="radio1"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio1">צפון</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.region === "center"}
                        {...register("region")}
                        value="center"
                        name="region" id="radio2"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio2">מרכז</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.region === "south"}
                        {...register("region")}
                        value="south"
                        name="region"
                        id="radio3"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio3">דרום</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.region === "jerusalem"}
                        {...register("region")}
                        value="jerusalem"
                        name="region"
                        id="radio4"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio4">ירושלים והסביבה</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.region === "noLimit"}
                        {...register("region")}
                        value="noLimit"
                        name="region"
                        id="radio5"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio5">אין מגבלה</OptionItemLabel>
                </OptionItem>
                <Spacer height={20}/>
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
