import React, {useEffect, useState} from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Label, Logo, LogoWrapper, OptionExplanation,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton, Spacer,
    StepWrapper, TextInput,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step3({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [showSecondLevelQuestions, setShowSecondLevelQuestions] = useState(false);
    const [showThirdLevelQuestions, setShowThirdLevelQuestions] = useState(false);
    const [showFourthLevelQuestions, setShowFourthLevelQuestions] = useState(false);
    const onSubmit = data => {
        // console.log(data)
        
        if (formData?.stage === "graduated") {
            handleNextClick(6)
        }else{
            if(formData.job === "referant"||formData.job === "mortgage"||
            formData.job === "hitum"||formData.job === "realestate")
            {
                handleNextClick(6);
            }else{
                handleNextClick();
            }
            
        }
        handleFormSubmit(data);
    }
    // useEffect(() => {
    //     if (formData?.stage !== "graduated") {
    //         handleNextClick(6)
    //     }
    // }, [watch])
    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            // console.log(formData)
            if(formData.job==="elemntari"|| formData.job==="pensioni" || formData.job==="elemntari_pension"
            || formData.job==="invesment_pension" )
            {
            if (value.stage === "haveExams") {
                setShowSecondLevelQuestions(true)
            } else {
                setShowSecondLevelQuestions(false)
                setShowThirdLevelQuestions(false)
                setShowFourthLevelQuestions(false)
            }

            if (value.stage === "haveExams" && value.examStage === "examStageA") {
                setShowThirdLevelQuestions(true)
            } else {
                setShowThirdLevelQuestions(false)
            }

            if (value.stage === "haveExams" && value.examStage === "examStageB") {
                setShowFourthLevelQuestions(true)
            } else {
                setShowFourthLevelQuestions(false)
            }
        }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return <StepWrapper>
        <Title>איזה שלב את/ה?</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>3. באיזה שלב של הלימודים את/ה נמצא/ת ? האם נותרו לך עוד בחינות לעבור ?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.stage === "graduated"}
                        {...register("stage")}
                        value="graduated"
                        name="stage"
                        id="radio1"
                        required
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio1">סיימתי את הלימודים</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.stage === "haveExams"}
                        {...register("stage")}
                        value="haveExams"
                        name="stage" id="radio2"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio2">אני עדיין בלימודים</OptionItemLabel>
                </OptionItem>
                {
                    showSecondLevelQuestions && (
                        <>
                            <Spacer height={20}/>
                            <QuestionLabel subQuestion>באיזה שלב אתה בחינות -</QuestionLabel>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examStage === "examStageA"}
                                    {...register("examStage")}
                                    value="examStageA"
                                    name="examStage"
                                    id="radio3"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio3">1. אני במהלך שלב א' - בחינות
                                    יסוד</OptionItemLabel>
                            </OptionItem>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examStage === "examStageB"}
                                    {...register("examStage")}
                                    value="examStageB"
                                    name="examStage"
                                    id="radio4"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio4">2. אני במהלך שלב ב' - בחינות
                                    גמר</OptionItemLabel>
                            </OptionItem>
                        </>
                    )
                }

                {
                    showThirdLevelQuestions && (
                        <>
                            <Spacer height={20}/>
                            <QuestionLabel subQuestion>כמה בחינות יסוד נותרו לך לעבור לפני שתוכל/י לגשת למקצועות הגמר
                                ?</QuestionLabel>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeft === "one"}
                                    {...register("examsLeft")}
                                    value="one"
                                    name="examsLeft"
                                    id="radio5"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio5">א. נותרה לי בחינת יסוד אחת</OptionItemLabel>
                            </OptionItem>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeft === "two"}
                                    {...register("examsLeft")}
                                    value="two"
                                    name="examsLeft"
                                    id="radio6"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio6">ב. נותרו לי 2 בחינות יסוד</OptionItemLabel>
                            </OptionItem>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeft === "three"}
                                    {...register("examsLeft")}
                                    value="three"
                                    name="examsLeft"
                                    id="radio7"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio7">ג. נותרו לי 3 בחינות יסוד
                                </OptionItemLabel>
                            </OptionItem>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeft === "four"}
                                    {...register("examsLeft")}
                                    value="four"
                                    name="examsLeft"
                                    id="radio8"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio8">ד. נותרו לי 4 בחינות יסוד </OptionItemLabel>
                            </OptionItem>
                            <Spacer height={20}/>
                        </>
                    )
                }
                {
                    showFourthLevelQuestions && (
                        <>
                            <Spacer height={20}/>
                            <QuestionLabel subQuestion>כמה בחינות גמר נותרו לך לעבור ?</QuestionLabel>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeftToPass === "one"}
                                    {...register("examsLeftToPass")}
                                    value="one"
                                    name="examsLeftToPass"
                                    id="radio9"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio9">א. נותרה לי בחינה אחת בלבד</OptionItemLabel>
                            </OptionItem>
                            <OptionItem>
                                <OptionItemInput
                                    defaultChecked={formData.examsLeftToPass === "two"}
                                    {...register("examsLeftToPass")}
                                    value="two"
                                    name="examsLeftToPass"
                                    id="radio10"
                                    type="radio"/>
                                <OptionItemLabel width={300} htmlFor="radio10">ב. נותרו לי 2 בחינות ומעלה</OptionItemLabel>
                            </OptionItem>
                            <Spacer height={20}/>
                        </>
                    )
                }
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
