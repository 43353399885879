import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Label, Logo, LogoWrapper, OptionExplanation,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton, Spacer,
    StepWrapper, TextInput,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step4({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {        
        handleFormSubmit(data);
        handleNextClick();
        // if (data?.internshipStatus === "notStarted") {
            
        //     handleNextClick();
        // } else {
        //     handleNextClick(8);
        // }
    }


    return <StepWrapper>
        <Title>מה לגבי התמחות?</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>4. האם התחלת או סיימת התמחות ?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.internshipStatus === "notStarted"}
                        {...register("internshipStatus")}
                        value="notStarted"
                        name="internshipStatus"
                        id="radio1"
                        type="radio"/>
                    <OptionItemLabel width={250} htmlFor="radio1">טרם התחלתי התמחות</OptionItemLabel>
                </OptionItem>
                {/* <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.internshipStatus === "startedNotFinished"}
                        {...register("internshipStatus")}
                        value="startedNotFinished"
                        name="internshipStatus" id="radio2"
                        type="radio"/>
                    <OptionItemLabel width={250} htmlFor="radio2">נשארו לי עודהתחלתי וטרם סיימתי</OptionItemLabel>
                </OptionItem> */}
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.internshipStatus === "finished"}
                        {...register("internshipStatus")}
                        value="finished"
                        name="internshipStatus" id="radio3"
                        type="radio"/>
                    <OptionItemLabel width={250} htmlFor="radio3">סיימתי התמחות</OptionItemLabel>
                </OptionItem>
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
