import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Logo, LogoWrapper,

    QuestionLabel,
    ReverseButton,
    StepWrapper, TextInput,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step9({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick,submitForm ,formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        
        
        handleFormSubmit(data);  
        
        formData.allowBackOfficeJobs=data.allowBackOfficeJobs
        
        
        submitForm(formData)      
        handleNextClick();
    }


    return <StepWrapper>
        <Title>מגוון המשרות שלנו..</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <QuestionLabel>האם יש מקומות עבודה שלא היית מעויניינ/ת שאעביר את הפרטים שלך אליהם ?
                </QuestionLabel>
                <TextInput defaultValue={formData.allowBackOfficeJobs}
                           placeholder={""} {...register("allowBackOfficeJobs")} />
                <Buttons>
                    <Button type="submit">שלח</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
