
export const jsonData={
// "job":
// {
// "ref_pen":"12",
// "ref_elem":"11",
// "hitumPrivate":"2",
// "hitumBusiness":"1",
// "hitumMedical":"3",
// "referant":"5",
// "mortgage":"4",
// "realestate":"10",
// "pensioni":"6",
// "elemntari":"7",
// "invesment":"8",
// },
"job":
{
    "ref_pen":"11",
    "ref_elem":"12",
    "hitumPrivate":"8",
    "hitumBusiness":"7",
    "hitumMedical":"9",
    "referant":"11",
    "mortgage":"10",
    "realestate":"14",
    "pensioni":"1",
    "elemntari":"2",
    "invesment":"3",
    }
 ,
"employmentType":{
"Independent":"2",
"Employee":"1"
},
"region":{
    "north":"1",
    "center":"2",
    "south":"3",
    "jerusalem":"5",
    "noLimit":"4"
},

"stage":{
    "graduated":"2",
     "haveExams":"1"
    
},
"examStage":{
    "examStageA":"נמצא בבחינות יסוד",
    "examStageB":"נמצא בבחינות גמר"    
},
"examsLeft":{

    "one":"נשארה בחינה 1",
    "two":"נשארו שתי בחינות",
    "three":"נשארו שלוש בחינות",
    "four":"נשארו ארבע בחינות"
},
"examsLeftToPass":{
    "one":"נשארה בחינת גמר אחת",
    "tow":"נשארו שתי בחינות גמר ומעלה "
},
"internshipStatus":{
    "notStarted":"1",
    "startedNotFinished":"2",
    "finished":"3"
},
"isCurrentlyInField":{
"true":"כן",
"false":"לא"
},
"availability":{
    "fullTime":"1",
    "partTime":"2",
    "flex":"3"
}
}