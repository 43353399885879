import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading, Logo, LogoWrapper,
    OptionItem, OptionItemInput, OptionItemLabel,
    QuestionLabel,
    ReverseButton,
    StepWrapper,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step6({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData}) {
    const {register, handleSubmit, formState: {errors}} = useForm();

    const onSubmit = data => {
        handleFormSubmit(data);
        handleNextClick();
    }


    return <StepWrapper>
        <Title></Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>6. האם יש לך ניסיון בתחום ?
                </QuestionLabel>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.isCurrentlyInField === "true"}
                        {...register("isCurrentlyInField")}
                        value="true"
                        name="isCurrentlyInField"
                        id="radio1"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio1">כן</OptionItemLabel>
                </OptionItem>
                <OptionItem>
                    <OptionItemInput
                        defaultChecked={formData.isCurrentlyInField === "false"}
                        {...register("isCurrentlyInField")}
                        value="false"
                        name="isCurrentlyInField" id="radio2"
                        type="radio"/>
                    <OptionItemLabel htmlFor="radio2">לא</OptionItemLabel>
                </OptionItem>
                {/* {showInput && (
                    <>
                        <Spacer height={50}/>
                        <Label>נשמח לדעת איפה!</Label>
                        <TextInput defaultValue={formData.where}
                                   placeholder={"מקום העבודה הנוכחי שלך"} {...register("where")} />
                    </>
                )} */}
                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
