import React from "react";
import {
    Button,
    Buttons,
    Form,
    FormWrapper, Heading,
     Logo, LogoWrapper,
    QuestionLabel,
    ReverseButton,
    Spacer,
    StepWrapper,
    TextInput,
    Title
} from "./style";
import {StepPercentage} from "./step-percentage";
import {useForm} from "react-hook-form";

export function Step8({stepPercentage, handleFormSubmit, handleNextClick, handlePrevClick, formData,submitForm}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        // submitForm()
        handleNextClick();
        handleFormSubmit(data);
    }


    return <StepWrapper>
        <Title>אפשר להתחיל..</Title>
        <LogoWrapper className="hideMobile">
            <Logo src={"/os-master-logo@2x.jpg"} alt={"logo"}/>
            <Heading src={"/os-master-logo-heading@2x.png"} alt={"heading"}/>
        </LogoWrapper>
        <FormWrapper>
            <StepPercentage percentageInfo={stepPercentage}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <QuestionLabel>8. מה ציפיות השכר ?</QuestionLabel>
                <Spacer height={20}/>
                <TextInput defaultValue={formData.salaryExpectation}
                           placeholder={"ציפיית השכר שלי "} {...register("salaryExpectation")} />

                <Buttons>
                    <Button type="submit">המשך</Button>
                    <ReverseButton onClick={handlePrevClick}>קודם</ReverseButton>
                </Buttons>
            </Form>

        </FormWrapper>
    </StepWrapper>
}
